
import { defineComponent, ref, watch, onMounted, Ref } from "vue";
import useEmitter from "@/composables/Emmiter";
import { useStore } from "vuex";
import FilterBase from "@/layout/header/partials/filters/FilterBase.vue";
import Api from "@/services/Api";
import { ElConfigProvider } from "element-plus";
import ptBr from "element-plus/lib/locale/lang/pt-br";

interface StoreFilter {
  nomeFantasia: string;
  cnpj: string;
  grupo: string;
  data: any;
  status: string;
}

export default defineComponent({
  name: "FilterStore",
  components: {
    FilterBase,
    ElConfigProvider,
  },

  setup(props, { emit }) {
    const store = useStore();
    const emitter = useEmitter();

    const activeModal = ref(false);
    const closeModal = ref(false);
    const storeGroups: Ref<any[]> = ref([]);
    const regioes: Ref<any[]> = ref([]);

    const storeFilter: Ref<StoreFilter> = ref({ nomeFantasia: "", cnpj: "", grupo: "", data: "", status: ""});

    async function getStoreGroups() {
      const { data } = await Api.get("getAllStoreGroup");
      storeGroups.value = data.grupos;
    }

    async function getRegioes() {
      const { data } = await Api.get("getRegioes");
      regioes.value = data.regioes;
    }

    async function enviarEmit() {
      const nonEmptyKeys = Object.keys(storeFilter.value).filter(key => {
        const value = storeFilter.value[key];
        if (Array.isArray(value)) {
          return value.length > 0;
        } else {
          return !!value;
        }
      });

      if (nonEmptyKeys.length >= 1) {
        localStorage.setItem('lojaSelecionada', JSON.stringify(storeFilter.value));
      } else {
        localStorage.removeItem('lojaSelecionada');
        localStorage.removeItem('lojaFixada')
      }

      storeFilter.value.cnpj = storeFilter.value.cnpj.replace(/[^\d]/g, '')

      await emitter.emit("FilterStore", {
        FiltroLojas: storeFilter.value,
      });

  closeModal.value = !closeModal.value;
}



    

    onMounted(() => {
      getStoreGroups();
      getRegioes();
    });

    return { storeFilter, storeGroups, enviarEmit, closeModal, regioes, ElConfigProvider, ptBr };
  },
});
